import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const TheUrologyPlaceLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Kella"
    institution="The Urology Place"
    referralCode="THEUROLOGYPLACE"
    physicianURL="https://www.theurologyplace.com/aboutdrnaveenkella"
  />
)

export default TheUrologyPlaceLandingPage
